import React, { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { useChatMCPModule } from "./useChatMCPModule";
import { AuthContext } from "../../context/authContext";

// Globally declare 'dataportalresources' object to access modules later
declare global {
    interface Window {
        // eslint-disable-next-line
        dataportalresources?: any;
    }
}


export interface ChatMCPProps{
    accessToken?: string;
    }
    
    const GetModule = (name: string) => {
        const authContext = useContext(AuthContext);
        const ALLOWED_ACCOUNTS = ["cimpress", "vistaprint"];
        const account: string | undefined = String(authContext?.profile?.['https://claims.cimpress.io/tenants']);
        const shouldForceLoadBundleNow = !window.dataportalresources || !window.dataportalresources["chatmcp"];
    
        // Inject the module, using a hook to avoid duplicating injection
        const { loadingBundle } = useChatMCPModule(shouldForceLoadBundleNow);
    
        const props = useMemo(() => ({
            accessToken: authContext?.accessToken
        }), [authContext?.accessToken]);
    
        // Callback to render the module under a DOM element
        const renderUnderParentElement = useCallback(async (domElement: HTMLDivElement | null) => {
            if (loadingBundle) {
                console.debug(`Still loading...[${loadingBundle}]`);
                return;
            }
    
            if (!window.dataportalresources?.["chatmcp"]) {
                console.error("Bundle not defined on the DOM! Did the request fail?");
                return;
            }
    
            console.debug("Bundle loading complete");
            const renderFunction = window.dataportalresources["chatmcp"]?.render;
            if (renderFunction && domElement) {
                await renderFunction(domElement, { ...props });
            }
        }, [props, loadingBundle]);
    
        if (!account || !ALLOWED_ACCOUNTS.includes(account.toLowerCase())) {
            return (
                <div style={{ textAlign: "center", padding: "20px", fontSize: "16px", fontWeight: "bold", color: "#d9534f" }}>
                    <p>🚫 Access Restricted</p>
                    <p>Your account <strong>{String(account)}</strong> does not have access to this module.</p>
                    <p>Please reach out to <a href="mailto:dune-support@example.com">dune@cimpress.com</a> to request access.</p>
                </div>
            );
        }
    
        // Renders the module under a parent DOM element
        const RenderedModule = () => {
            const domElement = useRef<HTMLDivElement | null>(null);
    
            useEffect(() => {
                if (domElement.current) {
                    renderUnderParentElement(domElement.current);
                }
            }, []);
    
            return <div ref={domElement} />;
        };
    
        RenderedModule.displayName = name;
    
        return <RenderedModule />;
    };
    

const createChatMCPModuleComponent = (name: string): React.FC<ChatMCPProps> => {
    const ChatMCPModule: React.FC<ChatMCPProps> = React.memo(() => {
        return GetModule(name);
    });

    ChatMCPModule.displayName = name;

    return ChatMCPModule;
};

export const ChatMCPModule = createChatMCPModuleComponent("ChatMcp");
