import { Clients } from "@cimpress-technology/data-portal-core";
import { parseUnknownErrorTypeToErrorMessage } from "@cimpress-technology/data-portal-core/lib/features/utils";
import { DataDiscoveryDatasetMetadata } from "@cimpress-technology/data-portal-core/lib/interfaces/dataDiscovery";
import { useState } from "react";
import { DataDiscoveryIdDecoder, DatasetIdFormatType, translateDatasetId, mapDecodedDatasetIdToDataDiscoveryFormat } from "../utils/dataset/datasetId";

type LoadingMetadata = {
    status: "loading";
}

type ErrorMetadata = {
    status: "error";
    error: Error;
}
const dataDiscoveryIdDecoder = new DataDiscoveryIdDecoder();

export function isLoadingMetadata(metadata: ReturnType["datasetIdToMetadataState"][string]): metadata is LoadingMetadata {
    return metadata != null && (metadata as LoadingMetadata).status === "loading";
}

export function isErrorMetadata(metadata: ReturnType["datasetIdToMetadataState"][string]): metadata is ErrorMetadata {
    return metadata != null && (metadata as ErrorMetadata).status === "error";
}

export function isDataDiscoveryDatasetMetadata(metadata: ReturnType["datasetIdToMetadataState"][string]): metadata is DataDiscoveryDatasetMetadata {
    return metadata != null && !(isLoadingMetadata(metadata) || isErrorMetadata(metadata));
}

export type DatasetIdToMetadataState = { [datasetId: string]: LoadingMetadata | DataDiscoveryDatasetMetadata | ErrorMetadata | null };

const fetchDataset = async (accessToken: string, dataDiscoveryFormatId: string, objectType: string | undefined) => {
    try {
        const datasetMetadataFromDD = await Clients.DataDiscoveryMetadataAPI.getDataset(accessToken, dataDiscoveryFormatId);
        return datasetMetadataFromDD;
    } catch (error) {
        if (error && error.toString().includes('404')) {
            const datasetMetadataFromID = dataDiscoveryIdDecoder.decode(dataDiscoveryFormatId);
            return mapDecodedDatasetIdToDataDiscoveryFormat(datasetMetadataFromID, dataDiscoveryFormatId, objectType);
        } else {
            throw error;
        }
    }
};

type ReturnType = {
    getMetadata: (datasetId: string, objectType: string| undefined) => Promise<void>;
    resetMetadata: (datasetId: string) => void;
    datasetIdToMetadataState: DatasetIdToMetadataState;
};

type Props = {
    accessToken: string;
}
const useDataDiscoveryMetadata = (props: Props): ReturnType => {
    const [metadata, setMetadata] = useState<ReturnType["datasetIdToMetadataState"]>({});
    const getMetadata = async (datasetId: string, objectType: string | undefined) => {
        const dataDiscoveryFormatId = translateDatasetId(datasetId, DatasetIdFormatType.DataDiscoveryId);
        const { accessToken } = props;
        if (!accessToken) {
            console.log('no access token');
            return;
        }
        if (metadata[dataDiscoveryFormatId]) {
            return;
        }
        try {
            setMetadata((prev) => ({ ...prev, [dataDiscoveryFormatId]: { status: "loading" } }));
            const result = await fetchDataset(accessToken, dataDiscoveryFormatId, objectType);
            setMetadata((prev) => ({ ...prev, [dataDiscoveryFormatId]: result }));
        } catch (error) {
            setMetadata((prev) => ({
                ...prev,
                [dataDiscoveryFormatId]: {
                    status: "error",
                    error: new Error(
                        parseUnknownErrorTypeToErrorMessage(error)
                    ),
                },
            }));
        }
    };
    const resetMetadata = (datasetId: string) => {
        const dataDiscoveryFormatId = translateDatasetId(datasetId, DatasetIdFormatType.DataDiscoveryId);
        setMetadata((prev) => ({ ...prev, [dataDiscoveryFormatId]: null }));
    };
    return {
        getMetadata,
        resetMetadata: resetMetadata,
        datasetIdToMetadataState: metadata,
    };
};

export default useDataDiscoveryMetadata;