import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AuthContext } from '../context/authContext';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Header from '@cimpress-technology/react-platform-header';
import IconBookOpen from '@cimpress-technology/react-streamline-icons/lib/IconBookOpen';

export const PlatformHeader: React.FC = () => {
  const { accessToken, profile, logout, login } = React.useContext(AuthContext);
  const history = useHistory();
  const [path, setPath] = useState(history.location.pathname || "/");

  history.listen((location) => setPath(location.pathname || '/'));

  const loggedIn = !!accessToken;
  const appLinks = [
    {
      id: 'dataPortalDiscover',
      content: <a onClick={() => {
        history.push('/legacy');
      }}>Data Portal &nbsp;&nbsp;<span className='dp-label-info-thin'>OLD</span></a>,
      isActive: path === '/legacy'
    },
    {
      id: 'dataPortal',
      content: <a onClick={() => {
        history.push('/');
      }}>Data Portal</a>,
      isActive: path === '/'
    },
    {
      id: 'dataProducts',
      content: <a onClick={() => {
        history.push('/dataproducts');
      }}>My Data Products</a>,
      isActive: path === '/dataproducts'
    },
    {
      id: 'dataContracts',
      content: <a onClick={() => {
        history.push('/data-contracts');
      }}>Data Contracts</a>,
      isActive: path.includes('/data-contracts')
    },
    {
      id: 'chatMCP',
      content: <a onClick={() => {
        history.push('/chatmcp');
      }}>ChatMCP</a>,
      isActive: path.includes('/chatmcp')
    },
    {
      id: 'servicesAndTools',
      content: <a onClick={() => {
        history.push('/dataservices');
      }}>Data  Services & Tools</a>,
      isActive: path === '/dataservices'
    },
    {
      id: 'documentation',
      content: <a style={{ padding: '16px 10px 11px' }} target='_blank' rel="noopener noreferrer"
        href='https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/8001587492/Data+Portal'>
        <IconBookOpen style={{ height: '19px' }} className="fas fa-book-open" />
      </a>
    }
  ];

  return (<div className="platform-header">
  <style>{`
    .platform-header .navbar-fluid .navbar {
      margin-bottom: 1px !important;
    }
  `}</style>
    <Header
      appTitle={<Link to={'/'}>Data Portal</Link>}
      appLinks={appLinks}
      profile={profile}
      onLogInClicked={login}
      onLogOutClicked={logout}
      isLoggedIn={loggedIn}
      accessToken={accessToken}
    />
  </div>);
};
