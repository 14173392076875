import { useState, useEffect } from 'react';
import { PdwAccessSearchProxy } from '../clients/pdwAccessSearchProxy';
import { ListDatasetsResponse } from '@cimpress-technology/data-portal-core/lib/interfaces/dataPortalAPI/internalPdwDatasets'; 

async function loadDatasets(
    action: () => Promise<void>, 
    store: PdwAccessSearchProxy,
    setDatasetResponse: (results: ListDatasetsResponse) => void,
    setStatus: (datsetStatus: string) => void
) {
    setStatus('loading');
    try {
        await action(); 
        setDatasetResponse(store.getResults());
        setStatus('succeeded');
    } catch (error) {
        console.error(error);
        setStatus('error');
    } 
}

export function useSearch(accessToken: string | undefined, account: string) {
    const [store] = useState(new PdwAccessSearchProxy());
    const [datsetStatus, setStatus] = useState('loading');
    const [datasetResponse, setDatasetResponse] = useState(null as ListDatasetsResponse | null);
    const [searchText, setSearchText] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const pageSize = 9;
    
    if (!accessToken) {
        setStatus('error');
        throw new Error("accessToken is required");
    }

    useEffect(() => {
        loadDatasets(() => store.initialize(accessToken, account), store, setDatasetResponse, setStatus);
    }, [accessToken, account, store]);

    const search = (query: string) => loadDatasets(() => store.search(accessToken, account, query), store, setDatasetResponse,  setStatus);
    const clear = () => loadDatasets(() => store.clearSearch(accessToken, account), store, setDatasetResponse, setStatus);
    const handlePageChange = (page: number) => loadDatasets(() => store.changePage(accessToken, account, page), store, setDatasetResponse,  setStatus);

    return { datasetResponse, datsetStatus,  paginationControl: {pageSize, currentPage, handlePageChange, setCurrentPage}, searchControl: {searchText, search, clear, setSearchText}, loadDatasets};
}
