import { colors } from "@cimpress/react-components";
import React from "react";
import ReactMarkdown from "react-markdown";

type Props = { description: string };

const MetadataDescription = (props: Props) => {
    return (
        <pre className="highlight-block" style={{ color: colors.cobalt.base, padding: 8, wordBreak: "keep-all" }}>
            <code>
                <ReactMarkdown>{props.description || ""}</ReactMarkdown>
            </code>
        </pre>
    );
};

export default MetadataDescription;

