import { useState, useEffect } from 'react';
import { Interfaces,Clients } from '@cimpress-technology/data-portal-core';

const loadDatasetInfo = async (accessToken, translatedDatasetId, setDatasetInfo, setLoading, setError, setNotFound) => {
    setLoading(true);
    setNotFound(false);
    setError(null);
    setDatasetInfo(null);

    if (accessToken && translatedDatasetId) {
        try {
            const info = await Clients.DataPortalAPI.getDatasetInfo(accessToken, translatedDatasetId);
            setDatasetInfo(info);
        } catch (error) {
            if (error && error.toString().includes('404')) {
                setNotFound(true);
                console.log('This error is expected and handled. It is because the dataset was not found in any of the data products.');
            } else {
                console.error(error);
                setError(error);
            }
        } 
    }

    setLoading(false);
};


export const useDatasetInfo = (accessToken: string | undefined, translatedDatasetId: string):{
    datasetInfo: Interfaces.DataPortalAPI.ExtendedDataProductDto | null;
    loadingDatasetInfo: boolean;
    datasetInfoError: Error | null;
    datasetInfoNotFound: boolean;
} => {

    const [datasetInfo, setDatasetInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [notFound, setNotFound] = useState(false);

    useEffect(() => {
        loadDatasetInfo(accessToken, translatedDatasetId, setDatasetInfo, setLoading, setError, setNotFound);
    }, [accessToken, translatedDatasetId]);

    return { datasetInfo, 
        loadingDatasetInfo: loading,
        datasetInfoError: error,
        datasetInfoNotFound: notFound
     };
};