import React, { useState } from "react";
import { Modal, Button, shapes } from "@cimpress/react-components";
import { AuthContext } from "../../../../../context/authContext";
import { ErrorDetails } from "../../../ErrorDetails";
import { Interfaces, Clients } from "@cimpress-technology/data-portal-core";
import { MetadataInfo } from "./MetadataInfo";
import { DatasetResponse } from "@cimpress-technology/data-portal-core/lib/interfaces/dataPortalApi";

export const ModalLink: React.FC<{
    datasetName: string;
    datasetId: string;
    hasDataProductReadAccess: boolean;
    hasDataProductAdminAccess: boolean;
    datasetDetails: DatasetResponse;
}> = ({
    datasetName,
    datasetId,
    hasDataProductAdminAccess,
    hasDataProductReadAccess,
    datasetDetails,
}) => {
    const { accessToken } = React.useContext(AuthContext);
    const [showDatasetMetadataModal, setShowDatasetMetadataModal] =
        useState(false);
    const [datasetMetadata, setdatasetMetadata] = useState(
        {} as Interfaces.DataDiscovery.DataDiscoveryDatasetMetadata
    );
    const [datasetMetadataLoaded, setDatasetMetadataLoaded] = useState(false);
    const [datasetMetadataLoadedError, setDatasetMetadataLoadedError] =
        useState(null as Error | null);

    const getDatasetMetadata = async (accessToken, datasetId) => {
        setDatasetMetadataLoaded(false);
        setShowDatasetMetadataModal(true);
        setDatasetMetadataLoadedError(null);
        try {
            const results = await Clients.DataDiscoveryMetadataAPI.getDataset(
                accessToken,
                datasetId
            );
            setdatasetMetadata(results);
        } catch (error) {
            console.error(error);
            setDatasetMetadataLoadedError(error as Error);
        }
        setDatasetMetadataLoaded(true);
    };

    return (
        <div>
            <Button
                variant="anchor"
                onClick={() => getDatasetMetadata(accessToken, datasetId)}
            >
                {datasetName}
            </Button>
            <Modal
                status={datasetMetadataLoadedError && !(datasetMetadataLoadedError.message.includes('404')) ? "danger" : "info"}
                show={showDatasetMetadataModal}
                onRequestHide={() => setShowDatasetMetadataModal(false)}
                title={datasetName}
                closeButton={true}
                size={"lg"}
                footer={
                    <button
                        className="btn btn-default"
                        onClick={() => setShowDatasetMetadataModal(false)}
                    >
                        Close
                    </button>
                }
            >
                { (datasetMetadataLoadedError && !(datasetMetadataLoadedError.message.includes('404'))) ? (
                    <ErrorDetails
                        title={datasetMetadataLoadedError.message}
                        error={datasetMetadataLoadedError}
                    />
                ) : datasetMetadata && datasetMetadataLoaded ? (
                    <MetadataInfo
                        dataset={datasetMetadata}
                        isSameBusiness={true}
                        hasDataProductReadAccess={hasDataProductReadAccess}
                        hasDataProductAdminAccess={hasDataProductAdminAccess}
                        datasetDetails={datasetDetails}
                    />
                ) : (
                    <div className="centerText">
                        <shapes.Spinner />
                        <span>Loading metadata......</span>
                    </div>
                )}
            </Modal>
        </div>
    );
};
