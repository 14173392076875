import { Interfaces, Clients } from "@cimpress-technology/data-portal-core";

export class PdwAccessSearchProxy {
    private qid: string | undefined = undefined;
    private query: string | undefined = undefined;
    private offset = 0;
    private limit = 9; 
    private results: Interfaces.DataPortalAPI.ListDatasetsResponse = { _embedded: { datasets: [] }, offset: 0};

    async initialize(accessToken: string, account: string) {
        const response = await Clients.DataPortalAPI.getInternalPdwDatasets(accessToken, account ,this.query, this.offset, this.limit, this.qid);
        this.qid = response.qid;
        this.results = response;
    }
    

    async search(accessToken: string, account:string, newQuery: string) {
        this.query = newQuery;
        this.offset = 0; 
        const response = await Clients.DataPortalAPI.getInternalPdwDatasets(accessToken, account, this.query, this.offset, this.limit, this.qid);
        this.results = response;
    }

    async clearSearch(accessToken: string,  account: string) {
        this.query = undefined;
        this.offset = 0;
        const response = await Clients.DataPortalAPI.getInternalPdwDatasets(accessToken, account, this.query, this.offset, this.limit, this.qid);
        this.results = response;
    }

    async changePage(accessToken: string,  account: string, newPage: number) {
        this.offset = (newPage) * this.limit;
        const response = await Clients.DataPortalAPI.getInternalPdwDatasets(accessToken, account, this.query, this.offset, this.limit, this.qid);
        this.results = response;
    }

    getResults() {
        return this.results;
    }
}

