import React from 'react';
import { shapes } from '@cimpress/react-components';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AuthContext } from './context/authContext';
import DiscoveryLanding from './components/screens/discovery/DiscoveryLanding';
import UserDataProducts from './components/screens/userDataProducts/UserDataProducts';
import { ErrorDetails } from './components/shared/ErrorDetails';
import { DataProductDetails } from './components/screens/dataProductDetails/DataProductDetails';
import { ServicesAndTools } from './components/screens/servicesAndTools/ServicesAndTools';
import { DataContractsManagementModule, DataContractDetailsModule } from "../src/components/dataContracts/DataContractsWrapper";
import { ChatMCPModule  } from "../src/components/chatMCP/chatMCPWrapper";
import Summary from './components/screens/summary/Summary';
import { Dashboard } from './components/screens/dashboard/Dashboard';
import {useDataContractsModule} from "./components/dataContracts/useDataContractsModule";
import LoadingDetails from "@cimpress-technology/data-portal-core/lib/components/LoadingDetails";
import { useIssueTrackerModule } from './components/issueTracker/useIssueTrackerModule';
import { IssueManagementModule } from './components/issueTracker/IssueTrackerWrapper';
import { useChatMCPModule } from './components/chatMCP/useChatMCPModule';

const loadingDataContractSpinner = (message: string) => <LoadingDetails data-testid='routes-contracs-loading-module-spinner' size="large" center={true} message={message} />;
const loadingDataIssueTrackerSpinner = (message: string) => <LoadingDetails data-testid='routes-issue-tracker-loading-module-spinner' size="large" center={true} message={message} />;
const loadingChatMCPSpinner = (message: string) => <LoadingDetails data-testid='routes-contracs-loading-module-spinner' size="large" center={true} message={message} />;
export const Routes: React.FC = () => {
  const authContext = React.useContext(AuthContext);
  const { isAuthenticating, authenticationError, login } = authContext;
  const {loadingBundle: loadingContractsBundle} = useDataContractsModule();
  const {loadingBundle: loadingIssueTrackerBundle} = useIssueTrackerModule();
  const {loadingBundle: loadingChatMCPBundle} = useChatMCPModule();
  if (isAuthenticating) {
    const { Spinner } = shapes;
    return (
      <div style={{ textAlign: 'center' }}>
        <Spinner />
        <span>Authenticating...</span>
      </div>
    );
  }

  if (authenticationError) {
    return <ErrorDetails title={'Error authenticating.'} error={authenticationError} retryFunction={login} />;
  }


  return <Switch>
    <Route exact path={'/legacy'} component={Dashboard} />
    <Route exact path={'/'} component={DiscoveryLanding} />
    <Route exact path={'/discover/:dataProductId'} component={Summary} />
    <Route exact path={'/dataservices'} component={ServicesAndTools} />
    <Route exact path={'/dataproducts'} component={UserDataProducts} />
    <Route exact path={'/dataproducts/:dataProductId'} component={DataProductDetails} />
    <Route exact path={'/data-contracts'} component={!loadingContractsBundle ? DataContractsManagementModule : () => loadingDataContractSpinner("Loading Data Contracts Module")} />
    <Route exact path={'/chatmcp'} component={!loadingChatMCPBundle ? ChatMCPModule : () => loadingChatMCPSpinner("Loading Data ChatMCP Module")} />
    <Route exact path={'/data-contracts/:dataContractGuid'} component={!loadingContractsBundle ? DataContractDetailsModule : () => loadingDataContractSpinner("Loading Data Contracts Module")} />
    <Route exact path={'/data-issues/issues/:issueId'} component={!loadingIssueTrackerBundle ? IssueManagementModule : () => loadingDataIssueTrackerSpinner("Loading Data Issue Tracker Module")} />
    <Route>
      <Redirect to="/" />
    </Route>
  </Switch>;
};

