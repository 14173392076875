import { Button, colors, Tooltip } from "@cimpress/react-components";
import { Spinner } from "@cimpress/react-components/lib/shapes";
import {
    IconAlertTriangle,
    IconAdd,
    IconCheck,
} from "@cimpress-technology/react-streamline-icons/lib";
import React, { useState } from "react";
import {
    addPdwDataset,
} from "@cimpress-technology/data-portal-core/lib/features/resourcesPdwDatasets/common";
import { AuthContext } from "../../../../../context/authContext";
import { useAppDispatch, useAppSelector } from "../../../../../store/storeHooks";
import { parseUnknownErrorTypeToErrorMessage } from "@cimpress-technology/data-portal-core/lib/features/utils";
import { DatasetIdFormatType, translateDatasetId } from "../../../../../utils/dataset/datasetId";
import { fetchResourcesPdwRoles } from "@cimpress-technology/data-portal-core/lib/features/resources/common";
import { useDatasetInfo } from "../../../../../hooks/useDatasetInfo";
import ConfirmationAlert from "../../../common/ConfirmationAlert";
import { PDWRoleType } from "@cimpress-technology/data-portal-core/lib/interfaces/dataPortalApi";
import { transferDatasetOwnership } from "@cimpress-technology/data-portal-core/lib/clients/dataPortalApi";
import { getPdwRoleByDataProductId } from "../../../../../utils/dataProduct";

const COMPONENT_HEIGHT = "36px";

type Props = {
    dataProduct: { dataProductId: string, isResourceControlled: boolean };
    datasetId: string;
    /** Holds the Data Product Id where the dataset is already in use (published).  */
    dataProductDomainId: string;
    isIncludedInThisDataProduct: boolean;
    isRestricted: boolean;
};

type transferStateType = {
    showModal: boolean,
    error: null | Error, 
    state: 'idle' | 'loading' | 'succeded' | 'error'
}

function getButton(props: Props, baseButton: JSX.Element, usedInDataProductId: string | undefined, datasetInfoNotFound: boolean): JSX.Element {
    function getButtonState(): { status: 'simpleAdd' | 'notPossibleAdd' | 'alreadyAdded' | 'initialState', isDisabled: boolean, reason: JSX.Element } {
        if (props.isIncludedInThisDataProduct) {
            return {
                status: 'alreadyAdded',
                isDisabled: true,
                reason: <span>
                    This PDW Dataset is a resource of your Data Product.
                </span>
            };
        }
        if (usedInDataProductId) {
            return {
                status: 'notPossibleAdd',
                isDisabled: true,
                reason: <span>
                    This PDW Dataset is used in another Data
                    Product &nbsp;
                    <a
                        href={`/dataProducts/${usedInDataProductId}?tab=Resources&subtab=resourcesPdwDataset`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {usedInDataProductId}
                    </a>
                    . You cannot add this PDW Dataset to your Data Product.
                </span>
            };
        }
        if (datasetInfoNotFound) {
            return {
                status: 'simpleAdd',
                isDisabled: false,
                reason: <></>
            };
            
        }
        return {
            status: 'initialState',
            isDisabled: false,
            reason: <></>
        };
    }
    const buttonState = getButtonState();
    const modifiedButton = React.cloneElement(baseButton, { disabled: buttonState.isDisabled });
    switch (buttonState.status) {
        case 'notPossibleAdd':
            return (
                <Tooltip
                    direction="left"
                    contents={buttonState.reason}
                >
                    {modifiedButton}
                </Tooltip>
            );
        case 'alreadyAdded':
            return (
                <Tooltip
                    direction="left"
                    contents={buttonState.reason}
                >
                    <IconCheck
                        weight="fill"
                        style={{ color: colors.success.base }}
                    />
                </Tooltip>
            );
        case 'simpleAdd':
            return modifiedButton;
        case 'initialState':
            return (
                < ></>
            );
    }
}

export const AddPdwDatasetButton = (props: Props): JSX.Element => {
    const dispatch = useAppDispatch();
    const { datasetsStatus, datasetsErrors } = useAppSelector(
        (state) => state.resourcesPdwDatasets
    );

    const { accessToken } = React.useContext(AuthContext);
    const  datasetIdInPdwFormat = translateDatasetId(props.datasetId, DatasetIdFormatType.PdwDatasetId);
    const [transferState, setTransferState] = useState<transferStateType>({
        showModal: false,
        error: null, 
        state: 'idle'
    });

    const { datasetInfo, loadingDatasetInfo, datasetInfoError, datasetInfoNotFound } = useDatasetInfo(accessToken, datasetIdInPdwFormat);

    if (accessToken == null) return <div>Access Token required.</div>;

    const dispatchAddPdwDataset = async (executeOnFulfilled:(()=>Promise<void>)[]) => {
        await dispatch(
            addPdwDataset({
                accessToken,
                dataProductId: props.dataProduct.dataProductId,
                resourceId: datasetIdInPdwFormat,
            })
        ).then(async (res) => {
            if (res.meta.requestStatus === "fulfilled")
                await secondaryEffectsAddPdwDataset(executeOnFulfilled);
        });
    };

    const secondaryEffectsAddPdwDataset = async (postProcessingSteps: (()=>Promise<void>)[]) => {
        await Promise.all(postProcessingSteps.map((asyncTask)=>asyncTask()));
    };

    const fetchPdwRoles = async () => {
        await dispatch(fetchResourcesPdwRoles({ accessToken, dataProductId: props.dataProduct.dataProductId }));
    };

    const transferOwnership = async () => {
        try {
            setTransferState(transferState => ({...transferState, error: null, state: 'loading'}));
            const adminPdwRole = getPdwRoleByDataProductId(props.dataProduct.dataProductId, PDWRoleType.Admin);
            await transferDatasetOwnership(accessToken, props.dataProduct.dataProductId, datasetIdInPdwFormat, adminPdwRole);
            setTransferState(transferState => ({...transferState, state: 'succeded'}));
        } catch (e) {
            setTransferState(transferState => ({...transferState, state: 'error', error: new Error(`Failed to transfer ownership: ${parseUnknownErrorTypeToErrorMessage(e)}`)}));
        }
    };

    const handleAddDatasetWithOwnershipTransfer = async () => {
        setTransferState(transferState => ({...transferState, showModal: false}));
        await dispatchAddPdwDataset([fetchPdwRoles, transferOwnership]);
    };

    const handleAddDatasetWithoutOwnershipTransfer = async () => {
        setTransferState(transferState => ({...transferState, showModal: false}));
        await dispatchAddPdwDataset([fetchPdwRoles]);
    };

    const AddButton = <Button
        variant="default"
        disabled={true}
        onClick={() => {
            if (props.isRestricted || props.dataProduct.isResourceControlled) {
                // For Resource-Controlled Data Product, Transfer of Ownership is handled at API level
                handleAddDatasetWithoutOwnershipTransfer();
            } else {
                setTransferState(transferState => ({ ...transferState, showModal: true }));
            }
        }}
    >
        <IconAdd weight="fill" />
    </Button>;

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: COMPONENT_HEIGHT,
            }}
        >   

            {(datasetsStatus[datasetIdInPdwFormat]?.add ===
                "failed" || transferState.error) && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <Tooltip
                        show={true}
                        contents={
                            <div style={{ wordWrap: "break-word" }}>
                                {parseUnknownErrorTypeToErrorMessage(
                                    datasetsErrors[
                                        datasetIdInPdwFormat
                                    ] || transferState.error
                                )}
                            </div>
                        }
                    >
                        <IconAlertTriangle
                            weight="fill"
                            style={{ color: colors.warning.base }}
                        />
                    </Tooltip>
                </div>
            )}
            {datasetInfoError && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <Tooltip
                        contents={
                            <div>
                                {parseUnknownErrorTypeToErrorMessage(
                                    datasetInfoError
                                )}
                            </div>
                        }
                    >
                        <IconAlertTriangle
                            weight="fill"
                            style={{ color: colors.warning.base }}
                        />
                    </Tooltip>
                </div>
            )}
            {(datasetsStatus[datasetIdInPdwFormat]?.add ===
                "loading" || loadingDatasetInfo || transferState.state=='loading') && (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Spinner size="medium" />
                </div>
            )}

            <>
                {!loadingDatasetInfo && ['error', 'succeded', 'idle'].includes(transferState.state) && !['loading', 'succeded'].includes(datasetsStatus[datasetIdInPdwFormat]?.add) && (
                    getButton(props, AddButton, datasetInfo?.dataProductId, datasetInfoNotFound)
                )}
            </>
            <ConfirmationAlert
                show={transferState.showModal}
                message={
                    <p>Do you want to transfer the ownership?</p>
                }
                onConfirm={handleAddDatasetWithOwnershipTransfer}
                onCancel={handleAddDatasetWithoutOwnershipTransfer}
                confirmButtonLabel="Yes"
                cancelButtonLabel="No"
            />
        </div>
    );
};
