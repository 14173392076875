import { ReactAppEnvironment } from "../../common";
import {useMicroFrontend} from "../microFrontend/useMicroFrontend";

// Define base URL depending on the environment. If the environment is set to Production, we are connecting to the production server.
// Otherwise, the connection is local.
const getBaseUrl = () => {
    return process.env.REACT_APP_ENVIRONMENT === ReactAppEnvironment.Production
        ? 'https://prd.ui.chatmcp.data.cimpress.io'
        : 'https://dev.ui.chatmcp.data.cimpress.io';
        // : 'http://localhost:3300'; //  For local
    // return 'https://mr-10.ui.chatmcp.data.cimpress.io'; for MR
    
};

export const useChatMCPModule = (forceLoadNow = false): {
    loadingBundle: boolean;
    loadBundleErrors: Error | null;
    loadBundleJs(): ReturnType<typeof loadBundleJs>;
} => {
    const bundleName = "index.js";
    const { loadingBundle, loadBundleErrors, loadBundleJs } = useMicroFrontend("chatmcp", `${getBaseUrl()}/${bundleName}`, forceLoadNow);
    return {
        loadingBundle,
        loadBundleErrors,
        loadBundleJs
    };
};
