import React from "react";
import Button from "@cimpress/react-components/lib/Button";
import Tooltip from "@cimpress/react-components/lib/Tooltip";

type Props = {
    isSameBusiness: boolean;
    datasetAccount: string;
    database: string;
    schema: string;
    name: string;
    description?: string;
};

const getTooltipProps = (isSameBusiness, description) => {
    switch (true) {
        case !isSameBusiness:
            return {
                show: undefined,
                contents: "You don't have access to this dataset in Data discovery"
            };
            case description === undefined:
            return {
                show: undefined,
                contents: "This dataset doesn't exist in Data discovery"
            };
        default:
            return {
                show: false,
                contents: ""
            };
    }
};

const OpenInDataDiscoveryButton = (props: Props) => {
    const toolTipDetails = getTooltipProps(props.isSameBusiness, props.description);
    return (
        <Tooltip
            show={toolTipDetails.show}
            contents={toolTipDetails.contents}
        >
            <Button
                size="sm"
                disabled={!props.isSameBusiness || (props.description === undefined)}
                onClick={() =>
                    window.open(
                        `https://data-discovery.cimpress.io/#/?selectedTreeItem=${props.datasetAccount}.eu-west-1.${props.database}.${props.schema}.${props.name}`,
                        "_blank",
                        "noopener,noreferrer"
                    )
                }
            >
                Open in Data Discovery
            </Button>
        </Tooltip>
    );
};

export default OpenInDataDiscoveryButton;
