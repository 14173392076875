import { DataDiscoveryDatasetMetadata } from "@cimpress-technology/data-portal-core/lib/interfaces/dataDiscovery";
interface DecodedDatasetId {
    account: string;
    region: string;
    database: string;
    schema: string;
    object: string;
}

interface DatasetIdDecoder {
    matchFormat(datasetId: string): RegExpMatchArray | null;
    decode(datasetId: string): DecodedDatasetId;
}

export class DataDiscoveryIdDecoder implements DatasetIdDecoder {
    matchFormat(datasetId: string): RegExpMatchArray | null {
        const regx = "(.+)[:]+[/]+[/]+(.+)[.]+(.*)[.]+(.*)[/]+(.*)";
        return datasetId.match(regx);
    }

    decode(datasetId: string): DecodedDatasetId {
        const match = this.matchFormat(datasetId);
        if (!match) {
            throw new Error(`Invalid resource id: ${datasetId}`);
        }
        return {
            account: match[2],
            region: match[3],
            database: match[1],
            schema: match[4],
            object: match[5],
        };
    }
}

export class PdwDatasetIdDecoder implements DatasetIdDecoder {
    matchFormat(datasetId: string): RegExpMatchArray | null {
        const regx =
            "([A-Za-z0-9_\\-]+)\\.([A-Za-z0-9_\\-]+)\\.([A-Za-z0-9_\\-]+)\\.([A-Za-z0-9_\\-]+)";
        return datasetId.match(regx);
    }

    decode(datasetId: string): DecodedDatasetId {
        const match = this.matchFormat(datasetId);
        if (!match) {
            throw new Error(`Invalid resource id: ${datasetId}`);
        }
        return {
            account: match[1],
            region: "eu-west-1",
            database: match[2],
            schema: match[3],
            object: match[4],
        };
    }
}

class DatasetIdTranslator {
    protected readonly decodedResourceId: DecodedDatasetId;
    constructor(decoded: DecodedDatasetId) {
        this.decodedResourceId = decoded;
    }
    translate(): string {
        throw new Error("Method not implemented.");
    }
}

class DataDiscoveryIdTranslator extends DatasetIdTranslator {
    translate(): string {
        const { account, region, database, schema, object } =
            this.decodedResourceId;
        return `${database.toUpperCase()}://${account.toLowerCase()}.${region.toLowerCase()}.${schema.toLowerCase()}/${object.toLowerCase()}`;
    }
}

class PdwDatasetIdTranslator extends DatasetIdTranslator {
    translate(): string {
        const { account, database, schema, object } = this.decodedResourceId;
        return `${account}.${database}.${schema}.${object}`.toUpperCase();
    }
}

function translatorFactory(decoderType: DatasetIdFormatType) {
    const translators: Record<DatasetIdFormatType, typeof DatasetIdTranslator> = {
        [DatasetIdFormatType.DataDiscoveryId]: DataDiscoveryIdTranslator,
        [DatasetIdFormatType.PdwDatasetId]:
            PdwDatasetIdTranslator,
    };
    return translators[decoderType];
}

enum DatasetIdDecoderType {
    DataDiscoveryId = "DataDiscoveryId",
    PdwDatasetId = "PdwDatasetId",
}

export enum DatasetIdFormatType {
    DataDiscoveryId = "DataDiscoveryId",
    PdwDatasetId = "PdwDatasetId",
}

export function translateDatasetId(datasetId: string, toFormat: DatasetIdFormatType) {
    const decoders: Record<DatasetIdDecoderType, DatasetIdDecoder> = {
        [DatasetIdDecoderType.PdwDatasetId]: new PdwDatasetIdDecoder(),
        [DatasetIdDecoderType.DataDiscoveryId]: new DataDiscoveryIdDecoder(),
    };
    for (const decoder of Object.values(decoders)) {
        if (decoder.matchFormat(datasetId)) {
            const decoded = decoder.decode(datasetId);
            const translator = translatorFactory(toFormat);
            const result = new translator(decoded).translate();
            return result;
        }
    }
    console.warn(`Input format for ${datasetId} is not recognized.`);
    return datasetId;
}

export function createDataDiscoveryDatasetId(
    account: string,
    database: string,
    schema: string,
    object: string
): string {
    const region = "eu-west-1";
    return `${database.toUpperCase()}://${account.toLowerCase()}.${region.toLowerCase()}.${schema.toLowerCase()}/${object.toLowerCase()}`;
}

export const mapDecodedDatasetIdToDataDiscoveryFormat = (decoded: DecodedDatasetId, dataDiscoveryFormatId: string, objectType: string | undefined): DataDiscoveryDatasetMetadata => {
    return {
        id: dataDiscoveryFormatId,
        cluster: `${decoded.account}.${decoded.region}`,
        database: decoded.database,
        schema: decoded.schema,
        name: decoded.object,
        columns: [],
        isView: objectType === 'VIEW' ? true : objectType === 'TABLE' ? false : undefined as unknown as boolean,
    };
};

export function getAccountFromCluster(cluster: string): string {
    return cluster.split(".")[0];
}
