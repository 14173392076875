import React, { ReactNode, useState, useEffect, useCallback } from "react";
import {
    TextField,
    Table,
    Alert,
    Drawer,
    Button,
    Pagination,
} from "@cimpress/react-components";
import {
    DataProduct,
} from "@cimpress-technology/data-portal-core/lib/interfaces/dataPortalApi";
import { Spinner } from "@cimpress/react-components/lib/shapes";
import {
    IconSearch, IconSynchronizeArrowsAlt
} from "@cimpress-technology/react-streamline-icons/lib";
import {
    DataPortalAPI
} from "@cimpress-technology/data-portal-core";
import { DatasetResponse } from "@cimpress-technology/data-portal-core/lib/interfaces/dataPortalAPI/internalPdwDatasets";
import { useAppDispatch, useAppSelector } from "../../../../../store/storeHooks";
import { setEditMode } from "@cimpress-technology/data-portal-core/lib/features/resourcesPdwDatasets/pdwDatasetsSlice";
import { AddPdwDatasetButton } from "./AddPdwDatasetButton";
import { ModalLink } from "./ModalLink";
import { useSearch } from "../../../../../hooks/useSearch";
import { AuthContext } from "../../../../../context/authContext";
import { createDataDiscoveryDatasetId } from "../../../../../utils/dataset/datasetId";

export const UserOwnedDatasetsDrawer: React.FC<{
    dataProduct: DataProduct;
    domain: DataPortalAPI.Domain;
    includedDatasetIds: string[];
    hasDataProductReadAccess: boolean;
    hasDataProductAdminAccess: boolean;
}> = ({
    dataProduct,
    domain,
    includedDatasetIds,
    hasDataProductAdminAccess,
    hasDataProductReadAccess,
}) => {
    // Drawer size to display
    const dispatch = useAppDispatch();
    const drawerSize = 0.55;

    const { editMode, pdwDatasetCollectionState } = useAppSelector(
        (state) => state.resourcesPdwDatasets
    );

    const { accessToken } = React.useContext(AuthContext);

    const {
        datasetResponse,
        datsetStatus,
        paginationControl: { pageSize, currentPage, handlePageChange, setCurrentPage },
        searchControl: { searchText, search, clear, setSearchText }
    } = useSearch(accessToken, dataProduct.dataProductPdwName);
    const [alertDismissed, setAlertDismissed] = useState(false);

    const databaseColumns = [
        {
            Header: "Action",
            accessor: ".",
            Cell: (row: { original: DatasetResponse } ) => {
                return (
                    <AddPdwDatasetButton
                        dataProduct={{ dataProductId: dataProduct.dataProductId, isResourceControlled: dataProduct.isResourceControlled}}
                        datasetId={createDataDiscoveryDatasetId(row.original.accountName,
                             row.original.databaseName, row.original.schemaName, row.original.objectName)}
                        dataProductDomainId={domain.domainId}
                        isIncludedInThisDataProduct={includedDatasetIds.includes(createDataDiscoveryDatasetId(row.original.accountName,
                            row.original.databaseName, row.original.schemaName, row.original.objectName))}
                        isRestricted={row.original.isRestricted}
                    />
                ) as ReactNode;
            },
        },
        {
            Header: "Object",
            accessor: "name",
            Cell: (row: { original: DatasetResponse } ) => {
                return (
                    <ModalLink
                        datasetName={row.original.objectName.toLowerCase()}
                        datasetId={createDataDiscoveryDatasetId(row.original.accountName,
                            row.original.databaseName, row.original.schemaName, row.original.objectName)}
                        hasDataProductReadAccess={hasDataProductReadAccess}
                        hasDataProductAdminAccess={hasDataProductAdminAccess}
                        datasetDetails={row.original}
                    />
                ) as ReactNode;
            },
        },
        {
            Header: "Database",
            accessor: "database",
            Cell: (row: { original: DatasetResponse }) => {
                return row.original.databaseName;
            },
        },
        {
            Header: "Schema",
            accessor: "schema",
            Cell: (row: { original: DatasetResponse }) => {
                return row.original.schemaName.toLowerCase();
            },
        },
        {
            Header: "PDW",
            accessor: "cluster",
            Cell: (row: { original: DatasetResponse }) => {
                return row.original.accountName.toLowerCase();
            },
        },
        {
            Header: "Owner Role",
            accessor: "ownershipRole",
            Cell: (row: { original: DatasetResponse }) => {
                return row.original.ownerRole;
            },
        },
    ];

    const datasetInfoAlert = (
        <Alert
            status="info"
            dismissible={true}
            dismissed={alertDismissed}
            onDismiss={() => setAlertDismissed(true)}
            style={{ paddingTop: 5, paddingBottom: 0, marginBottom: 15 }}
            message={
                <span>
                    <b>Info Tip:</b>
                    <ul>
                        <li>
                            These datasets are those <b>owned by roles that are accessible to you in Snowflake</b>,  
                            as well as shared resources like FIVETRAN or SEGMENT datasets.
                        </li>
                        <li>
                            To include River datasets, please remember to add the corresponding River streams to the Data Product.
                        </li>
                    </ul>
                </span>
            }
        />
    );

    const handleKeyPress = useCallback((event) => {
        if (event.key === 'Enter') {
            search(searchText);
            setCurrentPage(0);
        }
    }, [search, setCurrentPage, searchText]);

    useEffect(() => {
        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [handleKeyPress]);

    const onClose = () => {
        dispatch(setEditMode(false));
    };

    return (
        <>
            <Button
                className="pull-right"
                variant="primary"
                disabled={pdwDatasetCollectionState.status === 'loading'}
                onClick={() => {
                    dispatch(setEditMode(true));
                }}
            >
                + Add Datasets
            </Button>
            <Drawer
                show={editMode}
                onRequestHide={() => onClose()}
                size={drawerSize}
                closeOnClickOutside={true}
                footer={
                    <div className="col-xs-12">
                        <Button
                            className="pull-right"
                            onClick={() => onClose()}
                        >
                            Close
                        </Button>
                    </div>
                }
                bodyStyle={{ paddingBottom: 0 }}
            >
                {datsetStatus === "loading" && (
                    <>
                        <Spinner />
                        <p>Loading datasets that you own...</p>
                    </>
                )}
                {datsetStatus === "error" && (
                    <>
                        <p>Error in loading datasets!!<br /><strong>OR</strong><br />You are not allowed to add datasets to DataProducts belonging to this <strong>PDW</strong></p>
                    </>
                )}
                {datsetStatus === "succeeded" && (
                    <>
                        <div
                            className="row"
                            style={{ marginTop: -20 }}
                        >
                            <div className="col-md-12">{datasetInfoAlert}</div>
                        </div>
                        <div style={{ maxWidth: "100%"}}>
                            <div className="row">
                                <div className="col-md-7">
                                    <h2 style={{ margin: 0, paddingLeft: 0 }}>
                                        My Datasets
                                    </h2>
                                    <small style={{ color: "grey" }}>
                                        sourced from{" "}
                                        <a
                                            href="https://mcpapis.cimpress.io/api/7b720212-e64d-4db5-94ec-cc499e89b18a/openapi?env=api+docs+production#/Datasets/ListDatasets"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            PDW Access API
                                        </a>
                                    </small>
                                </div>
                                <div style={{display:'flex', alignItems:"flex-start"}}>
                                    <Button
                                            variant="primary"
                                            className="col-md-0.5"
                                            onClick={() => {
                                                clear();
                                                setSearchText('');
                                                setCurrentPage(0);
                                            }}
                                            style={{height: 48, marginRight: "1%"}}
                                        >
                                        <IconSynchronizeArrowsAlt size = 'lg' />
                                    </Button>
                                    <TextField
                                        className="col-md-8"
                                        value={searchText}
                                        placeholder={
                                            "Search for database schema or object"
                                        }
                                        helpText={`${datasetResponse?.total || 0} Datasets found`}
                                        onChange={(e) => {
                                            setSearchText(e.target.value || "");
                                        }}
                                        style={{ margin: 0, padding: 0 }}
                                        disabled={false}
                                    />
                                    <Button
                                        variant="primary"
                                        className="col-md-0.5"
                                        onClick={() => {
                                            search(searchText);
                                            setCurrentPage(0);
                                        }}
                                        style={{height: 48, marginLeft: "1%"}} 
                                    >
                                    <IconSearch size = 'lg'/>
                                    </Button>
                                </div>
                            </div>
                            <Table
                                hasHover={true}
                                data={datasetResponse?._embedded?.datasets || []}
                                columns={databaseColumns}
                                noDataText={
                                    searchText != ""
                                        ? "No datasets available matching the search"
                                        : "No datasets available"
                                }
                            />
                            <div>
                                {(datasetResponse?.total || 0) > pageSize ? (
                                    <div style={{ float: "right" }}>
                                        <Pagination
                                            disableInitialCallback={true}
                                            pageRangeDisplayed={1}
                                            marginPagesDisplayed={2}
                                            pageCount={Math.ceil((datasetResponse?.total || 0)/pageSize)}
                                            onPageChange={(p) => {
                                                handlePageChange(p.selected);
                                                setCurrentPage(p.selected);
                                            }}
                                            forcePage={currentPage}
                                            containerClassName={"pagination"}
                                        />
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </>
                )}
            </Drawer>
        </>
    );
};

